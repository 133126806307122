import { Box, Grid, Typography } from "@mui/material";
import News from "./News";
import news1 from '../assets/images/news/first.jpg';
import news2 from '../assets/images/news/Congratulations.gif';
import news3 from '../assets/images/news/fifa-world-cup-22.jpg';
import meeting2025img from '../assets/images/news/annual_genaral_meeting_2025.jpg';
function NewsBar() {
    const news = [
        {
            title: "Annual General Meeting (AGM) 2025",
            time: "25 Jab 2025 | 10:00AM",
            image: meeting2025img,
            des: `We are excited to announce that the Annual General Meeting of the Uva Wellassa University Alumni Association will be held as follows:
    
    🗓 Date: Saturday, 25th January 2025
    🕒 Time: 10.00 am onwards
    🌐 Venue: Virtual Meeting via Zoom

The deadline for applications for the positions in the upcoming year has now closed. We sincerely thank everyone who applied!

We warmly invite all registered alumni members and UWU graduates to join onwards observers) for this important gathering. This is a great opportunity to stay connected, share insights, and strengthen our alumni network.

`,
            link: "https://www.facebook.com/share/p/1KpST9F8cR/"
        },
        {
            title: "Celebrating Professor D.K.D.D. Jayasena’s Remarkable Achievement",
            time: "1 Sep 2023 | 8:35PM",
            image: news2,
            des: "Prof. D.K.D.D. Jayasena has been named among the world’s top 2% scientists, a prestigious recognition published by Elsevier. This award is a testament to his remarkable contributions in the field of Animal Science. Professor Jayasena is currently affiliated with the Department of Animal Science in the Faculty of Animal Science and Export Agriculture.\n" +
                "\n" +
                "Among a select group of 42 distinguished researchers from Sri Lanka featured in this elite 2% category, Professor Jayasena’s inclusion is a source of immense pride for the Uva Wellassa University and the entire scientific community of Sri Lanka.\n" +
                "\n" +
                "Link this URL with more icon ",
            link: "https://www.uwu.ac.lk/professor-d-k-d-d-jayasena-has-been-recognized-as-one-of-the-top-2-scientists-globally-according-to-the-elsevierstanford-list/"
        },
        {
            title: "Department of Animal Science, UWU represents FIFA World Cup 2022",
            time: "1 Sep 2023 | 8:35PM",
            image: news3,
            des: "Dr.Kaushalya Wijayasekara, a Senior Lecturer attached to the Department of Animal Science, Uva Wellassa University was privileged to be a part of the Food Safety team of FIFA World Cup 2022 which was held in Qatar. The Football World Cup is the most prestigious association football tournament in the world. This event is considered as the most widely viewed and followed single sporting event in the world"
            , link: "https://www.uwu.ac.lk/department-of-animal-science-uwu-represents-fifa-world-cup-2022/"
        }
    ]

    return (
        <Box sx={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            marginTop: '50px',
            marginBottom: '100px'
        }}>

            <Box sx={{
                width: '85%'
            }}>
                <Typography align={'left'} width={'100%'} variant={'h3'} fontWeight={'600'}
                    sx={{
                        color: '#091D3D'
                    }}>Recent News
                </Typography>

                <Grid container spacing={3} sx={{
                    marginTop: '50px',
                }}>
                    {
                        news.map((item, index) => (
                            <Grid item md={4}>
                                <News link={item.link} time={item.time} image={item.image} des={item.des} title={item.title} />
                            </Grid>
                        ))
                    }

                </Grid>

            </Box>

        </Box>
    )
}
export default NewsBar