import { Box, CircularProgress, Grid, keyframes, Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Eventgen from "./Eventgen";
import event1img from '../assets/images/events/Web 2.jpg';
import event2img from '../assets/images/events/Vc Sir Size 02.jpg';
import event3img from '../assets/images/events/se.jpg';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

function EventBar() {
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const events = [
        {
            title: 'UWU Legends League 2025',
            description: `The Alumni Association of Uva Wellassa University is thrilled to announce the 3rd UWU Legends League, taking place on March 1st, 2025, at 9:00 AM at Shalika Ground, Narahenpita. This cherished tradition unites alumni from 14 graduating batches for a thrilling cricket tournament, fostering camaraderie and sportsmanship.`,
            image: event1img,
            href: '',
            time: '2025-03-01T09:00:00Z'
        },
        {
            title: 'Mr. Charith Wimaladharma Scholarship Program for UWU Undergraduates',
            description: `Alumni Association of Uva Wellassa University is embarking on a scholarship scheme “CHARITHA WIMALADHARMA MEMORIAL SCHOLARSHIP” to UWU Undergraduates.`,
            image: event3img,
            href: 'https://forms.gle/1kx89UeA6DXJBkL89',
            time: '2024-03-30T00:00:00Z'
        },
        {
            title: 'Felicitation for Vice Chancellor',
            description: `Professor Jayantha Lal Rathnasekara the Vice Chancellor of Uva Wellassa University has been in office for the last six years at Uva Wellassa University. `,
            image: event2img,
            href: '',
            time: '2023-12-10T00:00:00Z'
        }
    ];

    const handleImageLoad = () => {
        setLoading(false);
    };

    const backbtnH = () => {
        setLoading(true);
        setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : events.length - 1));
    };

    const nexbtnH = () => {
        setLoading(true);
        setIndex((prevIndex) => (prevIndex < events.length - 1 ? prevIndex + 1 : 0));
    };
    useEffect(() => {
        const img = new Image();
        console.log(events[index].image);
        img.src = events[index].image;
        img.onload = () => setLoading(false);
        img.onerror = (err) => console.error("Failed to load image", err);
    }, [index]);

    return (
        <>
            <Typography
                align="right"
                variant="h3"
                sx={{
                    width: { xs: "98%", sm: "98%", md: "98%", lg: "90%", xl: "90%" },
                    color: '#091D3D',
                    fontWeight: '600',
                    marginBottom: '50px',
                    marginTop: '50px'
                }}
            >
                Our Events
            </Typography>
            <Box
                sx={{
                    width: { xs: "98%", sm: "98%", md: "98%", lg: "90%", xl: "90%" },
                    height: { xs: "auto", sm: "auto", md: "auto", lg: "480px", xl: "480px" },
                    display: 'flex',
                    justifyContent: 'right',
                    position: 'relative',
                    marginBottom: '150px',
                }}
            >
                <Box
                    sx={{
                        width: '65%',
                        height: '100%',
                        display: { xs: "none", sm: "none", md: "none", lg: "block", xl: "block" },
                        position: 'relative',
                        paddingBottom: '50px',
                        background: '#EFF1F4'
                    }}
                />
                <Box
                    sx={{
                        position: { xs: "relative", sm: "relative", md: "relative", lg: "absolute", xl: "absolute" },
                        left: '0',
                        zIndex: '3',
                        height: '90%',
                        width: '98%',
                        padding: '3% 0% 5% 0%'
                    }}
                >
                    <Grid container spacing={5}>
                        <Grid item md={12} lg={6} sm={12} xs={12}>
                            <Box sx={{ position: 'relative', height: '400px', overflow: 'hidden' }}>
                                <Box
                                    key={index}
                                    sx={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        animation: `${fadeIn} 0.3s ease-in-out`
                                    }}
                                >
                                    {loading ? (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                           <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                                        </Box>
                                    ) : (
                                        <img
                                            key={index}
                                            src={events[index].image}
                                            alt="Event"
                                            onLoad={handleImageLoad}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                transition: 'opacity 0.3s ease-in-out'
                                            }}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={12} lg={6} sm={12}>
                            <Eventgen
                                nextH={nexbtnH}
                                backH={backbtnH}
                                time={events[index].time}
                                title={events[index].title}
                                href={events[index].href}
                                description={events[index].description}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
}

export default EventBar;
