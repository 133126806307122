import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { theme } from "../stylesheet";
import { useCountdown } from "../functions/useCountdown";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
  title: string;
  description: string;
  time: string;
  href: string;
  nextH: () => void;
  backH: () => void;
}

function Eventgen(prop: Props) {
  const targetDate = prop.time;
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const [isEventPassed, setIsEventPassed] = useState(false);
  const [changeEvent, setChangeEvent] = useState(false);
  const descRef = useRef<HTMLDivElement>(null);
  const [descHeight, setDescHeight] = useState(90); // Default height

  // Check if the event time has passed
  useEffect(() => {
    const eventTime = new Date(targetDate).getTime();
    const currentTime = new Date().getTime();
    setIsEventPassed(currentTime > eventTime);
  }, [targetDate, changeEvent]);

  // Update timer height based on description height
  useEffect(() => {
    if (descRef.current) {
      setDescHeight(descRef.current.clientHeight);
    }
  }, [prop.description]);

  return (
    <Box>
      {/* Countdown Timer (Conditional Rendering) */}
      {!isEventPassed && (
        <Box sx={{ display: 'flex', justifyContent: 'left', height: `${descHeight}px` }}>
          <Box>
            <Grid container spacing={4}>
              {[
                { value: days, label: 'Days' },
                { value: hours, label: 'Hours' },
                { value: minutes, label: 'Minutes' },
                { value: seconds, label: 'Seconds' },
              ].map((item, idx) => (
                <Grid item md={3} key={idx}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{
                      background: theme.palette.secondary.main,
                      padding: '10px',
                      color: theme.palette.customColors.brandColors.tertiary,
                      width: '100%',
                      textAlign: 'center',
                      fontSize: "30px",
                      fontWeight: '600'
                    }}>
                      {item.value}
                    </Box>
                    <Typography width={"100%"} align={'center'}>{item.label}</Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: '20px' }}>
            <Typography variant={"h6"} sx={{ marginRight: '30px', fontWeight: '600', display: { xs: 'none', md: 'block' } }}>
              Remaining
            </Typography>
          </Box>
        </Box>
      )}

      {/* Event Details */}
      <Box sx={{ margin: { xs: "20px", md: "50px 50px 0px 10px" } }}>
        <Typography variant={'h5'} fontWeight={'600'}>{prop.title}</Typography>
        <Box
          ref={descRef}
          sx={{
            marginTop: '20px',
            overflowX: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar': { width: '4px' },
            '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '4px' },
            '&::-webkit-scrollbar-thumb': { background: '#c0c0c0', borderRadius: '4px' },
            '&::-webkit-scrollbar-thumb:hover': { background: '#555' },
          }}
        >
          <Typography>{prop.description}</Typography>
        </Box>
        <Button
          onClick={() => window.open(prop.href, '_blank')}
          color={'primary'}
          sx={{
            background: theme.palette.primary.main,
            color: theme.palette.customColors.brandColors.tertiary,
            marginTop: '20px',
            borderRadius: '0px',
            padding: '16px 23px !important',
            "&:hover": { background: '#934d10 !important' }
          }}
        >
          Join with Us
        </Button>
      </Box>

      {/* Navigation Buttons */}
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'right' }}>
        <Box
          onClick={() => {
            prop.backH();
            setChangeEvent(!changeEvent);
          }}
          sx={{
            background: theme.palette.secondary.main,
            color: 'white',
            width: '20px',
            height: '20px',
            padding: '10px 10px 10px 15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2px',
            cursor: 'pointer'
          }}
        >
          <ArrowBackIosIcon />
        </Box>
        <Box
          onClick={() => {
            prop.nextH();
            setChangeEvent(!changeEvent);
          }}
          sx={{
            background: theme.palette.secondary.main,
            color: 'white',
            width: '20px',
            height: '20px',
            padding: '10px 10px 10px 15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '2px',
            cursor: 'pointer'
          }}
        >
          <ArrowForwardIosIcon />
        </Box>
      </Box>
    </Box>
  );
}

export default Eventgen;
